
export default {

    // 获取cookie
    getCookie:function (cname) {//cname->cookie名称
        let name = cname + "=";
        let ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i].trim();
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
    },

    //设置cookie
    setCookie:function (cname, value, days){ // cname->cookie名称  value->设置内容  days->设置日期
        let keepday = days || 1;
        var d = new Date();
        d.setTime(d.getTime() + (keepday*24*60*60*1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + value + "; " + expires;
    },

    //移除cookie
    removeCookie:function (cname){
        this.setCookie(cname,"", -1);
    },

}