import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/components/main/main';
import VueI18n from 'vue-i18n';
import messages from '@/il18n/index';
Vue.use(Router)
Vue.use(VueI18n)
const i18nConfig = {
    locale:window.localStorage.getItem('think-lang')?window.localStorage.getItem('think-lang'):'zh_cn',
    messages
}
const i18n = new VueI18n(i18nConfig)
const il8n_text = i18n.vm.messages[i18n.vm.locale];




const routes = [
    {
        path:'/',
        component: Main,
        name: 'home',
        header: 'home',
        meta: {
            title: il8n_text.home_index,
            auth:true,
        },
        children:[
            {
                path: '/',
                name: `homeIndex`,
                meta: {
                    title: il8n_text.home_index,
                    auth:true,
                },
                component: () => import('@/pages/index/index')
            },
        ],
    },
    {
        path:'/admin/',
        component: Main,
        children:[
            {
                path: '/admin/user',
                name: `userIndex`,
                meta: {
                    auth: true,
                    title: il8n_text.用户列表
                },
                component: () => import('@/pages/user/index')
            },
            {
                path: '/admin/setting',
                name: `setting`,
                meta: {
                    auth: true,
                    title: il8n_text.系统设置
                },
                component: () => import('@/pages/setting/index')
            },
            {
                path: '/admin/order',
                name: 'order',
                meta: {
                    title: il8n_text.order_management
                },
                component: () => import('@/pages/order/index')
            },
            {
                path: '/admin/detail',
                name: 'detail',
                meta: {
                    title: il8n_text.order_detail
                },
                component: () => import('@/pages/order/detail')
            },
            {
                path: '/admin/report',
                name: 'report',
                meta: {
                    title: il8n_text.pack_index
                },
                component: () => import('@/pages/report/index')
            },
            {
                path: '/admin/pack',
                name: 'pack',
                meta: {
                    title: il8n_text.大件包列表
                },
                component: () => import('@/pages/pack/index')
            },
            {
                path: '/admin/pack_detail',
                name: 'pack',
                meta: {
                    title: il8n_text.pack_detail
                },
                component: () => import('@/pages/pack/detail')
            },
            {
                path: '/admin/add_article/:id?',
                name: 'add_article',
                meta: {
                    title: il8n_text.pack_detail
                },
                component: () => import('@/pages/article/add_article')
            },
            {
                path: '/admin/article',
                name: 'article',
                meta: {
                    title: il8n_text.文章管理
                },
                component: () => import('@/pages/article/index')
            }
        ],
    },



    {
        path: '/login',
        name: 'login',
        meta: {
            title: il8n_text.登录,
        },
        component: () => import('@/pages/login/index')
    },
    {
        path: '/error_404',
        name: '404',
        meta: {
            title: il8n_text.error_404,
        },
        component: () => import('@/pages/error_index/404')
    },
]

const router = new Router({
    mode: 'hash',
    routes,
    //每个页面跳转后，都跳转到页面的顶部
    scrollBehavior (to) {
        let siteName = '';
        if(Object.keys(window.localStorage.getItem('SITE_INFO')).length){
            siteName = JSON.parse(window.localStorage.getItem('SITE_INFO')).siteName;
        }else{
            siteName = Vue.prototype.$store.getters.getSiteState.siteName;
        }

        document.title = to.meta.title;
        window.scrollTo(0, 0)
        return { x: 0, y: 0 }
    }
})


router.beforeEach(async(to, from, next)=>{
    //判断页面是否存在
    if(!to.matched.some(_ => _.meta)){
        next({
            name:'404'
        })
    }
  

    // 判断是否需要登录
    if(to.matched.some(_ => _.meta.auth)){
        // 需要登陆判断是否存有token
        const token = Vue.prototype.$utils.getCookie('token');
        if(token && token !== 'undefined'){
            next();
        }else{
            //如果没有token则跳往登陆页面
            next({
                name:'login'
            })
        }
    }else{
        //不需要直接通过
        next();
    }
 
})


export default router