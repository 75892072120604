export default{
    think_lang:'简体中文',
    home_index:'主页',
    '用户列表':'用户列表',
    '系统设置':'系统设置',
    '登录':'登录',
    error_404:'当前页面不存在',
    chinese: '简体中文',
    russian: 'Рабочий стол',
    english: 'English',
    staging: '工作台',
    logout: '退出登录',
    '代理仓每日出入库数据': '代理仓每日出入库数据',
    '每日订单趋势': '每日订单趋势',
    order_data: '订单数据',
    see_more: '查看更多',
    received: '已入库',
    packaging: '打包中',
    Packaged: '已打包',
    '欢迎登录': '欢迎登录',
    '代理账号': '代理账号',
    '请输入代理账号': '请输入代理账号',
    '请输入登录密码': '请输入登录密码',
    '记住密码': '记住密码',
    '登录密码': '登录密码',
    order_info: '订单信息',
    order_no: '订单号',
    order_status: '订单状态',
    '创建时间': '创建时间',
    user_id: '用户ID',
    '用户名称': '用户名称',
    delivery_time: '发货时间',
    user_info: '收件人信息',
    '姓名': '姓名',
    '城市': '城市',
    mobile: '电话',
    '详细地址': '详细地址',
    '国家': '国家',
    area: '地区',
    zip_code: '邮编',
    wu: '无',
    '包裹信息': '包裹信息',
    '快递单号': '快递单号',
    '物品名': '物品名',
    '计费重量': '计费重量',
    shelves_num: '货位',
    in_stock_img: '入库图片',
    '状态': '状态',
    report_time: '报备时间',
    zhang_wu: '暂无',
    enter_time: '入库时间',
    up_time: '上架时间',
    '打包信息': '打包信息',
    '仓库名称': '仓库名称',
    '路线名称': '路线名称',
    '用户备注': '用户备注',
    '平台备注': '平台备注',
    '打包序号': '打包序号',
    '运单号': '运单号',
    '原始重量': '原始重量',
    '实重': '实重',
    '体积重': '体积重',
    '长宽高': '长宽高',
    '打包图片': '打包图片',
    '打包备注': '打包备注',
    '计费信息': '计费信息',
    '实际运费': '实际运费',
    '报价备注': '报价备注',
    '实付金额': '实付金额',
    'RUB': 'RUB',
    '数据统计': '数据统计',
    search_order_keyword: '搜索订单关键词...',
    '全部': '全部',
    treat_picked: '待拣货',
    treat_pack: '待打包',
    treat_quotation: '待报价',
    treat_pay: '待支付',
    treat_confirm: '待确认',
    treat_delivery: '待发货',
    stop_delivery: '已发货',
    stop_complete: '已完成',
    '用户信息': '用户信息',
    take_info: '收货信息',
    '操作': '操作',
    '详情': '详情',
    treat_warehousing: '待入库',
    off_shelf: '已下架',
    '已签收': '已签收',
    Unnamed_package: '无名包裹',
    '物品信息': '物品信息',
    '体积': '体积',
    warehousing_warehouse: '入库仓库',
    '添加用户': '添加用户',
    '邮箱': '邮箱',
    '请输入邮箱': '请输入邮箱',
    '密码': '密码',
    '请输入密码': '请输入密码',
    '收件人姓名': '收件人姓名',
    '请输入收件人姓名': '请输入收件人姓名',
    '联系电话': '联系电话',
    '请输入联系电话': '请输入联系电话',
    '请输入国家': '请输入国家',
    '请输入城市': '请输入城市',
    '请输入地址': '请输入地址',
    Enable: '启用',
    close: '关闭',
    '取消': '取消',
    '修改': '修改',
    '添加': '添加',
    '搜索用户名': '搜索用户名/ID...',
    '注册时间': '注册时间',
    '编辑': '编辑',
    '共': '共',
    '条': '条',
    order_management: '订单管理',
    order_detail: '订单详情',
    pack_index: '包裹列表',
    '退出登录确认': '退出登录确认',
    '您确定退出当前账户吗？': '您确定退出当前账户吗？',
    '确认': '确认',
    logout_success: '您已成功退出',
    '添加banner图': '添加banner图',
    '修改banner图': '修改banner图',
    '图片名称': '图片名称',
    image_path: '图片路径',
    image_ch: '中文图片',
    image_ru: '俄文图片',
    image_us: '英文图片',
    '排序': '排序',
    '删除': '删除',
    '请输入图片名称': '请输入图片名称',
    image_rn: '俄文图片',
    display_or_not: '是否显示',
    display: '显示',
    hide: '隐藏',
    remark: '备注',
    input_remark: '请输入备注',
    set_up: '设置',
    signature: '国外签收',
    grounding: '国外上架',
    '大件包列表': '大件包列表',
    '待出仓': '待出仓',
    '已出仓': '已出仓',
    '大件包号': '大件包号',
    '搜索件包关键词': '搜索件包关键词...',
    pack_detail: '件包详情',
    '大件包信息': '大件包信息',
    '大件包状态': '大件包状态',
    '包裹数据': '包裹数据',
    '已入库包裹': '已入库包裹',
    '待入库包裹': '待入库包裹',
    '已下架包裹': '已下架包裹',
    '已打包包裹': '已打包包裹',
    '国外已签收': '国外已签收',
    '本月已完成订单数': '本月已完成订单数',
    '本月订单总额': '本月订单总额',
    '用户数据': '用户数据',
    '用户总数': '用户总数',
    '本月新增用户': '本月新增用户',
    '代理仓数据': '代理仓数据',
    '在库包裹': '在库包裹',
    '今日入库': '今日入库',
    '今日打包': '今日打包',
    '今日国外签收': '今日国外签收',
    order_price: '订单金额',
    order_sum: '订单数量',
    be_put_in_storage: '入库',
    stock_removal: '出库',
    '请输入名称': '请输入名称',
    '联系地址': '联系地址',
    merged_pack: '已合包',
    '基础设置': '基础设置',
    '页面设置': '页面设置',
    '公告设置': '公告设置',
    '添加公告': '添加公告',
    '公告标题': '公告标题',
    notice_content: '公告内容',
    '浏览量': '浏览量',
    '请输入公告标题': '请输入公告标题',
    '修改公告': '修改公告',
    '请输入公告内容': '请输入公告内容',
    '提交': '提交',
    image_type: '图片类型',
    home_page: '首页',
    logistics_page: '物流查询页',
    faq_page: '常见问题页',
    package_page: '包裹预报页',
    my_page: '关于我们页',
    freight_page: '运费预估页',
    '导出': '导出',
    '用户标识': '用户标识',
    '请输入用户标识': '请输入用户标识',
    '只能是1~6位的数字或字母': '只能是1~6位的数字或字母',
    '入库图片': '入库图片',
    leave_a_message: '留言',
    input_content: '请输入内容',
    message_record: '留言记录',
    select_order: '请选择订单',
    '添加包裹': '添加包裹',
    user_uid: '用户UID',
    report_no: '包裹单号',
    item_name: '物品名称',
    caterty_type: '货物类别',
    '包裹备注': '包裹备注',
    '活跃情况': '活跃情况',
    '请选择活跃时间': '请选择活跃时间',
    '时间段未下单': '时间段未下单',
    '时间段下单过': '时间段下单过',
    '时间段未报备': '时间段未报备',
    '时间段报备过': '时间段报备过',
    '时间段未访问': '时间段未访问',
    '时间段访问过': '时间段访问过',
    '首次访问': '首次访问',
    '关键字搜索': '关键字搜索',
    '开始时间': '开始时间',
    '结束时间': '结束时间',
    search_report_keyword: '搜索包裹关键字...',
    '公告内容（中文）': '公告内容（中文）',
    '公告内容（俄文）': '公告内容（俄文）',
    '公告内容（英文）': '公告内容（英文）',
    '公告标题（中文）': '公告标题（中文）',
    '公告标题（俄文）': '公告标题（俄文）',
    '公告标题（英文）': '公告标题（英文）',
    '包裹类型': '包裹类型',
    '别称': '别称',
    '州/城市': '州/城市',
    goods_money: '申报价值',
    '是否需要木架': '是否需要木架',
    '费用信息': '费用信息',
    collect_payment: '到付金额',
    yes: '是',
    no: '否',
    build_wooden_frame: '打木架',
    reinforce_free: '木架费',
    premium_fee: '保险费',
    '请求失败': '请求失败',
    '标签': '标签',
    '出库时间': '出库时间',
    '签收时间': '签收时间',
    '关键词': '关键词',
    '清关时间': '清关时间',
    '包号日期': '包号日期',
    '请选择搜索时间': '请选择搜索时间',
    '搜索时间': '搜索时间',
    '批量导出包裹': '批量导出包裹',
    '编辑包裹': '编辑包裹',
    '重量': '重量',
    '长': '长',
    '宽': '宽',
    '高': '高',
    '请填写ID号段为900001-999999范围内的数字': '请填写ID号段为900001-999999范围内的数字',
    '文章管理': '文章管理',
    '添加文章': '添加文章',
    '文章图片': '文章图片',
    '文章名称': '文章名称',
    '时间': '时间',
    '修改文章': '修改文章',
    '文章标题（中文）': '文章标题（中文）',
    '文章标题（俄文）': '文章标题（俄文）',
    '文章标题（英文）': '文章标题（英文）',
    '请输入文章标题': '请输入文章标题',
    '文章内容（中文）': '文章内容（中文）',
    '文章内容（俄文）': '文章内容（俄文）',
    '文章内容（英文）': '文章内容（英文）',
    '文章管理': '文章管理',
    '返回': '返回',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
}