import request from '@/libs/request';

/**
 * 登录
 * @param {*} data 
 * @returns 
 */
export function login(data){
    return request({
        url: 'login',
        method: 'post',
        params: data
    })
}
/**
 * 退出登录
 * @returns 
 */
export function logout(){
    return request({
        url: 'logout',
        method: 'post'
    })
}
/**
 * 获取代理信息
 * @returns 
 */
export function get_user_data(){
    return request({
        url: 'get_user_data',
        method: 'get'
    })
}
/**
 * 用户列表
 * @param {*} data 
 * @returns 
 */
export function my_user_list(data){
    return request({
        url: 'my_user_list',
        method: 'get',
        params: data
    })
}
/**
 * 包裹列表
 * @param {*} data 
 * @returns 
 */
export function my_report_list(data){
    return request({
        url: 'my_report_list',
        method: 'get',
        params: data
    })
}
/**
 * 包裹tab数据
 * @param {*} data 
 * @returns 
 */
export function get_report_tabs(data){
    return request({
        url: 'get_report_tabs',
        method: 'get',
        params: data
    })
}
/**
 * 订单列表
 * @param {*} data 
 * @returns 
 */
export function my_order_list(data){
    return request({
        url: 'my_order_list',
        method: 'get',
        params: data
    })
}
/**
 * 订单tab数据
 * @param {*} data 
 * @returns 
 */
export function get_order_tabs(data){
    return request({
        url: 'get_order_tabs',
        method: 'get',
        params: data
    })
}
/**
 * 订单详情
 * @param {*} data 
 * @returns 
 */
export function get_order_detail(data){
    return request({
        url: 'get_order_detail',
        method: 'get',
        params: data
    })
}
/**
 * 获取所有地区
 * @returns 
 */
export function area_all_list(){
    return request({
        url: 'area_all_list',
        method: 'get'
    })
}
/**
 * 获取所有地区
 * @param {*} data 
 * @returns 
 */
export function city_all_list(data){
    return request({
        url: 'city_all_list',
        method: 'get',
        params: data
    })
}
/**
 * 获取用户详情
 * @param {*} data 
 * @returns 
 */
export function get_user_info(data){
    return request({
        url: 'get_user_info',
        method: 'get',
        params: data
    })
}
/**
 * 添加用户
 * @param {*} data 
 * @returns 
 */
export function add_user_submit(data){
    return request({
        url: 'add_user_submit',
        method: 'post',
        params: data
    })
}
/**
 * 代理banner图列表
 * @param {*} data 
 * @returns 
 */
export function get_agent_banner(data){
    return request({
        url: 'get_agent_banner',
        method: 'get',
        params: data
    })
}
/**
 * 删除代理banner图
 * @param {*} data 
 * @returns 
 */
export function agent_banner_delete(data){
    return request({
        url: 'agent_banner_delete',
        method: 'delete',
        params: data
    })
}
/**
 * 添加代理banner图
 * @param {*} data 
 * @returns 
 */
export function agent_banner_add(data){
    return request({
        url: 'agent_banner_add',
        method: 'post',
        params: data
    })
}
/**
 * 件包tab数据
 * @param {*} data 
 * @returns 
 */
export function get_pack_tabs(data){
    return request({
        url: 'get_pack_tabs',
        method: 'get',
        params: data
    })
}
/**
 * 件包列表
 * @param {*} data 
 * @returns 
 */
export function my_pack_list(data){
    return request({
        url: 'my_pack_list',
        method: 'get',
        params: data
    })
}
/**
 * 件包详情
 * @param {*} data 
 * @returns 
 */
export function get_pack_detail(data){
    return request({
        url: 'get_pack_detail',
        method: 'get',
        params: data
    })
}
/**
 * 获取首页统计
 * @returns 
 */
export function get_home_statistics(){
    return request({
        url: 'get_home_statistics',
        method: 'get'
    })
}
/**
 * 订单趋势
 * @returns 
 */
export function get_order_tendency(){
    return request({
        url: 'get_order_tendency',
        method: 'get'
    })
}
/**
 * 包裹出入库统计
 * @returns 
 */
export function get_leave_report(){
    return request({
        url: 'get_leave_report',
        method: 'get'
    })
}
/**
 * 修改代理信息
 * @param {*} data 
 * @returns 
 */
export function edit_agent_info(data){
    return request({
        url: 'edit_agent_info',
        method: 'post',
        params: data
    })
}
/**
 * 公告列表
 * @param {*} data 
 * @returns 
 */
export function get_agent_notice(data){
    return request({
        url: 'get_agent_notice',
        method: 'get',
        params: data
    })
}
/**
 * 公告删除
 * @param {*} data 
 * @returns 
 */
export function agent_notice_delete(data){
    return request({
        url: 'agent_notice_delete',
        method: 'delete',
        params: data
    })
}
/**
 * 公告添加-修改
 * @param {*} data 
 * @returns 
 */
export function agent_notice_add(data){
    return request({
        url: 'agent_notice_add',
        method: 'post',
        params: data
    })
}
/**
 * 大件包导出
 * @param {*} data 
 * @returns 
 */
export function exports_big_pack(data){
    return request({
        url: 'exports_big_pack',
        method: 'post',
        params: data
    })
}
/**
 * 用户留言列表
 * @param {*} data 
 * @returns 
 */
export function get_order_msg_list(data){
    return request({
        url: 'get_order_msg_list',
        method: 'get',
        params: data
    })
}
/**
 * 留言回复
 * @param {*} data 
 * @returns 
 */
export function add_order_message(data){
    return request({
        url: 'add_order_message',
        method: 'post',
        params: data
    })
}
/**
 * 获取物品分类
 * @returns 
 */
export function get_category(){
    return request({
        url: 'get_category',
        method: 'get'
    })
}
/**
 * 单个批量报备
 * @param {*} data 
 * @returns 
 */
export function addition_report(data){
    return request({
        url: 'addition_report',
        method: 'post',
        params: data
    })
}
/**
 * 大件包一键导出
 * @param {*} data 
 * @returns 
 */
export function jy_big_pack(data){
    return request({
        url: 'jy_big_pack',
        method: 'post',
        params: data
    })
}
/**
 * 包裹详情
 * @param {*} data 
 * @returns 
 */
export function get_report_info(data){
    return request({
        url: 'get_report_info',
        method: 'get',
        params: data
    })
}
/**
 * 修改包裹
 * @param {*} data 
 * @returns 
 */
export function edit_report(data){
    return request({
        url: 'edit_report',
        method: 'post',
        params: data
    })
}
/**
 * 代理端添加|修改文章
 * @param {*} data 
 * @returns 
 */
export function saveArticle(data){
    return request({
        url: 'saveArticle',
        method: 'post',
        params: data
    })
}
/**
 * 获取文章列表
 * @param {*} data 
 * @returns 
 */
export function getArticleList(data){
    return request({
        url: 'getArticleList',
        method: 'get',
        params: data
    })
}
/**
 * 文章删除
 * @param {*} id 
 * @returns 
 */
export function deleteArticle(id){
    return request({
        url: `deleteArticle/${id}`,
        method: 'delete'
    })
}
/**
 * 获取单个文章数据
 * @param {*} id 
 * @returns 
 */
export function getArticleInfo(id){
    return request({
        url: `getArticleInfo/${id}`,
        method: 'get'
    })
}
