<template>
    <div class="_root" style="transition-duration:0.5s;" :style="{width:(!showNav?'165rem':'0rem')}">
        <div class="  _pc-w-165">
            <div @click="toNavPath(item.url)" v-for="(item,index) in list" :key="index" :class="$router.app._route.fullPath == item.url?'_white theme-color-bg':'_grey-99'" style="cursor:pointer">
                <div class="_pc-pad-x-10 _pc-flex-row-mid _w-max _pc-pad-y-20 ">
                    <div :class="$router.app._route.fullPath == item.url?'_white-bg':'_grey-ff-bg'"  class="_pc-w-10 _pc-h-10 _border-circle _pc-mar-right-10 "></div>
                    <div class="_pc-font-14" slot="title">{{item.title}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {getNavBar} from '@/api/services.js'
export default {
    name: 'NavLeftBar',

    data() {
        return {
            list:[],
        };
    },

    props:{
        showNav:Boolean,
        navPath:String,
    },
    mounted() {
        this.getNavBar();
    },
    methods: {
        getNavBar(){
            let list = [
                {
                    title: this.$t('home_index'),
                    url:'/'
                },
                {
                    title: this.$t('用户列表'),
                    url:'/admin/user'
                },
                {
                    title: this.$t('order_management'),
                    url: '/admin/order'
                },
                {
                    title: this.$t('pack_index'), 
                    url: '/admin/report'
                },
                {
                    title: this.$t('大件包列表'), 
                    url: '/admin/pack'
                },
                {
                    title: this.$t('文章管理'), 
                    url: '/admin/article'
                },
                {
                    title: this.$t('set_up'),
                    url:'/admin/setting'
                },
            ];
            this.list = list;
        },
        toNavPath(path){
            if(this.$router.app._route.fullPath == path) return;
            this.$router.push({path})
        },
    },
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 100%;
    /* padding-top:15rem; */
}

.el-menu-item{
    padding:0 15rem;
}
.getNavPath{
    background-color: #579AFF;
    color: #fff;
    border-radius: 8rem;
}
._root{
    height:100%;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-y: auto;
    overflow-x:hidden ;
    /* background-image: url('@/assets/images/nav-left-bg.png'); */
    background-color: #383838;
    background-size: 100%;
}

._root::-webkit-scrollbar {
    display: none;
}

.nav-img-box{
    z-index: 2;
    height: 65rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right:solid 1rem #e6e6e6;
}
.el-menu{
    background-color:unset ;
}
</style>