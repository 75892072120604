import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import utils from'@/utils/utils.js';

//swiper
import '@/assets/css/swiper.css'
//icon
import '@/assets/icon/iconfont1/iconfont.css';
// pc css
import '@/assets/css/app.css';
// mb css
// import '@/assets/css/mbapp.css';

//element-ui框架
import {
  popover,
  badge,
  divider,
  button,
  menu,
  submenu,
  menuItemGroup,
  menuItem,
  link,
  input,
  pagination,
  loading,
  radio,
  upload,
  dialog,
  table,
  tableColumn,
  Switch,
  form,
  formItem,
  dropdown,
  dropdownMenu,
  dropdownItem,
  checkbox,
  Avatar,
  Row,
  Col,
  Image,
  Select,
  Option,
  InputNumber,
  RadioGroup,
  Radio,
  Icon,
  DatePicker
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(button);
Vue.use(menu);
Vue.use(submenu);
Vue.use(menuItemGroup);
Vue.use(menuItem);
Vue.use(link);
Vue.use(divider);
Vue.use(badge);
Vue.use(popover);
Vue.use(input);
Vue.use(pagination);
Vue.use(loading);
Vue.use(upload);
Vue.use(dialog);
Vue.use(radio);
Vue.use(table);
Vue.use(tableColumn);
Vue.use(Switch);
Vue.use(form);
Vue.use(formItem);
Vue.use(dropdown);
Vue.use(dropdownMenu);
Vue.use(dropdownItem);
Vue.use(checkbox);
Vue.use(Avatar);
Vue.use(Row);
Vue.use(Col);
Vue.use(Image);
Vue.use(Select);
Vue.use(Option);
Vue.use(InputNumber);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Icon);
Vue.use(DatePicker);

// 多语言
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

// 图片预览
import Viewer from 'v-viewer' // 引入js
import 'viewerjs/dist/viewer.css' // 引入css
Vue.use(Viewer) // 默认配置写法

let lang = 'ru';
let Url = window.location.origin;
if(Url.includes('agent.rangbuypro.com')){
  lang = 'en_us';
}

import messages from '@/il18n/index';
const i18nConfig = {
	locale:window.localStorage.getItem('think-lang')?window.localStorage.getItem('think-lang'):lang,
  messages
}
const i18n = new VueI18n(i18nConfig)





import { Message } from 'element-ui';
Vue.prototype.$message  = Message;
import { MessageBox } from 'element-ui';
Vue.prototype.$MessageBox  = MessageBox;

Vue.prototype.$utils  = utils;
Vue.prototype.$store = store;
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
