
export default{
    // 初始化数据
    state:{
        siteName:'',
    },

    getters:{
        getSiteState(state){
            return state;
        },
    },

    //更新数据
    mutations:{
        setSiteName(state, site){
            state.siteName = site.siteName;
        },
    },

    //更新数据方法（异步）
    actions:{
        getSiteName({ state, commit }){
            let site = {}
            setTimeout(()=>{
                site.siteName = '航达集运';
                commit('setSiteName',site);
                window.localStorage.setItem('SITE_INFO',JSON.stringify(site));
            },300)
        },
    },
}