import axios from "axios";
import utils from'@/utils/utils.js';
import Setting from '@/setting';
import router from '@/router';

import { Message } from 'element-ui';

//定义取消请求
const service = axios.create({
    baseURL: Setting.URL,
    timeout: 30000, // 请求超时时间
})
// 携带cookie
axios.defaults.withCredentials = true 
// 拦截器
service.interceptors.request.use(
    config=>{
        config.baseURL = Setting.URL;
        if (config.file) {
            config.headers['Content-Type'] = 'application/json; charset=utf-8';
        }
        const token = utils.getCookie('token') || '';
        config.headers['Authori-zation'] = 'Bearer '+ token;
        config.headers['think-lang'] = window.localStorage.getItem('think-lang') || 'ru';
        return config
    },
    error=>{
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    response => {
        let status = response.data ? response.data.status : 0
        const code = status
        if (code == 200) {
            return response.data;
        }else if(code == 400 || code == 400011 || code == 400012){
            return Promise.reject(response.data || { msg: '未知错误' })
        } else if (code >= 10000 && code <= 20000) {
            return Promise.reject(response.data || { msg: '未知错误' })
        } else if (code == 501 || code == 410000 || code == 410001 || code == 410002) {
            Message.error(response.msg)
            utils.removeCookie('token','',-1);
            router.replace({ path:'/login'})
        }
    },
    error => {
        Message.error(this.$t('请求失败'))
        return Promise.reject(error.msg)
    }
)


export default service