export default{
    think_lang:'English',
    home_index:'Home',
    '用户列表':'User list',
    '系统设置':'System settings',
    '登录':'Login',
    error_404:'The current page does not exist',
    chinese: '简体中文',
    russian: 'Рабочий стол',
    english: 'English',
    staging: 'Staging',
    logout: 'Log out of login',
    '代理仓每日出入库数据': 'Daily inbound and outbound data of proxy warehouse',
    '每日订单趋势': 'Daily order trend',
    order_data: 'Order data',
    see_more: 'See more',
    received: 'Received',
    packaging: 'Packaging',
    Packaged: 'Packaged',
    '欢迎登录': 'Welcome to login',
    '代理账号': 'Proxy account',
    '请输入代理账号': 'Please enter a proxy account',
    '请输入登录密码': 'Please enter your login password',
    '记住密码': 'Remember password',
    '登录密码': 'Login password',
    order_info: 'Ordering Information',
    order_no: 'Order No',
    order_status: 'Order status',
    '创建时间': 'Creation time',
    user_id: 'User ID',
    '用户名称': 'User name',
    delivery_time: 'Delivery time',
    user_info: 'Recipient information',
    '姓名': 'Name',
    '城市': 'City',
    mobile: 'Phone',
    '详细地址': 'Address',
    '国家': 'Country',
    area: 'Area',
    zip_code: 'Zip code',
    wu: 'Not have',
    '包裹信息': 'Package Information',
    '快递单号': 'Tracking number',
    '物品名': 'Article name',
    '计费重量': 'Charged weight',
    shelves_num: 'Goods allocation',
    in_stock_img: 'Storage image',
    '状态': 'Status',
    report_time: 'Reporting time',
    zhang_wu: 'None',
    enter_time: 'Warehousing time',
    up_time: 'Listing time',
    '打包信息': 'Packaging information',
    '仓库名称': 'Warehouse name',
    '路线名称': 'Route Name',
    '用户备注': 'User comments',
    '平台备注': 'Platform notes',
    '打包序号': 'Packaging number',
    '运单号': 'Waybill number',
    '原始重量': 'Original weight',
    '实重': 'Actual weight',
    '体积重': 'Volume weight',
    '长宽高': 'Dimensions',
    '打包图片': 'Packaging pictures',
    '打包备注': 'Packaging remarks',
    '计费信息': 'Billing information',
    '实际运费': 'Actual shipping cost',
    '报价备注': 'Quotation remarks',
    '实付金额': 'Paid-in amount',
    'RUB': 'RUB',
    '数据统计': 'Data statistics',
    search_order_keyword: 'Search order keywords...',
    '全部': 'All',
    treat_picked: 'Goods to be picked',
    treat_pack: 'To be packaged',
    treat_quotation: 'To be quoted',
    treat_pay: 'To be paid',
    treat_confirm: 'To be confirmed',
    treat_delivery: 'To be shipped',
    stop_delivery: 'Shipped',
    stop_complete: 'Completed',
    '用户信息': 'User information',
    take_info: 'Receiving information',
    '操作': 'operate',
    '详情': 'Details',
    treat_warehousing: 'To be stored',
    off_shelf: 'Removed from shelves',
    '已签收': 'Signed for',
    Unnamed_package: 'Unnamed package',
    '物品信息': 'Item Information',
    '体积': 'Volume',
    warehousing_warehouse: 'Warehousing warehouse',
    '添加用户': 'Add user',
    '邮箱': 'Email',
    '请输入邮箱': 'Please enter your email address',
    '密码': 'Password',
    '请输入密码': 'Please enter the password',
    '收件人姓名': 'Recipient Name',
    '请输入收件人姓名': "Please enter the recipient's name",
    '联系电话': 'Telephone',
    '请输入联系电话': 'Please enter the contact phone number',
    '请输入国家': 'Please enter the country',
    '请输入城市': 'Please enter the city',
    '请输入地址': 'Please enter the address',
    Enable: 'Enable',
    close: 'Close',
    '取消': 'Cancellation',
    '修改': 'Modify',
    '添加': 'Add',
    '搜索用户名': 'Search username/ID...',
    '注册时间': 'Registration time',
    '编辑': 'Edit',
    '共': 'Common',
    '条': 'strip',
    order_management: 'Order management',
    order_detail: 'Order details',
    pack_index: 'Package list',
    '退出登录确认': 'Exit login confirmation',
    '您确定退出当前账户吗？': 'Are you sure to exit the current account?',
    '确认': 'Confirm',
    logout_success: 'You have successfully exited',
    '添加banner图': 'Add banner image',
    '修改banner图': 'Modify banner image',
    '图片名称': 'Image name',
    image_path: 'Image path',
    image_ch: 'Chinese picture',
    image_ru: 'Russian Picture',
    image_us: 'English Picture',
    '排序': 'Sort',
    '删除': 'Delete',
    '请输入图片名称': 'Please enter a picture name',
    image_rn: 'Russian picture',
    display_or_not: 'Display or not',
    display: 'Display',
    hide: 'Hide',
    remark: 'Remark',
    input_remark: 'Please enter a comment',
    set_up: 'Set_up',
    signature: 'Foreign receipt',
    grounding: 'Overseas listing',
    '大件包列表': 'List of large packages',
    '待出仓': 'To be released from warehouse',
    '已出仓': 'Out of warehouse',
    '大件包号': 'Large package number',
    '搜索件包关键词': 'Search for package keywords...',
    pack_detail: 'Package details',
    '大件包信息': 'Large package information',
    '大件包状态': 'Large package status',
    '包裹数据': 'Package data',
    '已入库包裹': 'Received package',
    '待入库包裹': 'Package to be stored',
    '已下架包裹': 'Package removed from shelves',
    '已打包包裹': 'Packaged package',
    '国外已签收': 'Received abroad',
    '本月已完成订单数': 'Number of completed orders this month',
    '本月订单总额': 'Total order amount for this month',
    '用户数据': 'User data',
    '用户总数': 'Total number of users',
    '本月新增用户': 'New users added this month',
    '代理仓数据': 'Agent warehouse data',
    '在库包裹': 'Package in stock',
    '今日入库': 'Warehousing today',
    '今日打包': 'Pack Today',
    '今日国外签收': 'Received abroad today',
    order_price: 'Order price',
    order_sum: 'Order quantity',
    be_put_in_storage: 'Warehousing',
    stock_removal: 'Outbound',
    '请输入名称': 'Please enter a name',
    '联系地址': 'Contact address',
    merged_pack: 'Merge Package',
    '基础设置': 'Basic settings',
    '页面设置': 'Page settings',
    '公告设置': 'Announcement settings',
    '添加公告': 'Add announcement',
    '公告标题': 'Announcement title',
    notice_content: 'Announcement content',
    '浏览量': 'Page View',
    '请输入公告标题': 'Please enter the announcement title',
    '修改公告': 'Modify Announcement',
    '请输入公告内容': 'Please enter the announcement content',
    '提交': 'Submit',
    image_type: 'Image type',
    home_page: 'Home page',
    logistics_page: 'Logistics Query Page',
    faq_page: 'FAQ page',
    package_page: 'Package Forecast Page',
    my_page: 'About Our Page',
    freight_page: 'Freight Estimate Page',
    '导出': 'Export',
    '用户标识': 'User Code',
    '请输入用户标识': 'Please enter the user ID',
    '只能是1~6位的数字或字母': 'The value contains only 1 to 6 digits or letters',
    '入库图片': 'Storage image',
    leave_a_message: 'Leave a message',
    input_content: 'Please enter content',
    message_record: 'Message Record',
    select_order: 'Please select an order',
    '添加包裹': 'Add Package',
    user_uid: 'User UID',
    report_no: 'Parcel tracking number',
    item_name: 'Item name',
    caterty_type: 'Category of goods',
    '包裹备注': 'Package remarks',
    '活跃情况': 'Active situation',
    '请选择活跃时间': 'Please select an active time',
    '时间段未下单': 'No orders placed during the time period',
    '时间段下单过': 'Single pass during the time period',
    '时间段未报备': 'Time period not reported',
    '时间段报备过': 'Reported during the time period',
    '时间段未访问': 'Time period not visited',
    '时间段访问过': 'Visited during the time period',
    '首次访问': 'First visit',
    '关键字搜索': 'keyword search',
    '开始时间': 'Start Time',
    '结束时间': 'End Time',
    search_report_keyword: 'Search for package keywords...',
    '公告内容（中文）': 'Announcement Content (Chinese)',
    '公告内容（俄文）': 'Announcement Content (Russian)',
    '公告内容（英文）': 'Announcement Content (English)',
    '公告标题（中文）': 'Announcement Title (Chinese)',
    '公告标题（俄文）': 'Announcement Title (Russian)',
    '公告标题（英文）': 'Announcement Title (English)',
    '包裹类型': 'Package Type',
    '别称': 'Another namer',
    '州/城市': 'State/City',
    goods_money: 'Declared value',
    '是否需要木架': 'Whether a wooden frame is needed',
    yes: 'Yes',
    no: 'No',
    '费用信息': 'Cost information',
    collect_payment: 'Amount collected',
    build_wooden_frame: 'Pack in wooden crate',
    reinforce_free: 'Timbering cost',
    premium_fee: 'Premium',
    '请求失败': 'Request failed',
    '标签': 'Label',
    '出库时间': 'Delivery time',
    '签收时间': 'Signing time',
    '关键词': 'Keyword',
    '清关时间': 'custom clearance',
    '包号日期': 'Packet number date',
    '请选择搜索时间': 'Please select a search time',
    '搜索时间': 'Search time',
    '批量导出包裹': 'Bulk export package',
    '编辑包裹': 'Edit package',
    '重量': 'weight',
    '长': 'length',
    '宽': 'Wide',
    '高': 'Height',
    '请填写ID号段为900001-999999范围内的数字': 'Please enter the number in the range of 900001-999999',
    '文章管理': 'Article management',
    '添加文章': 'Add article',
    '文章图片': 'Article picture',
    '文章名称': 'Article name',
    '时间': 'Time',
    '修改文章': 'Edit article',
    '文章标题（中文）': 'Article Title (Chinese)',
    '文章标题（俄文）': 'Article title (Russian)',
    '文章标题（英文）': 'Article title (English)',
    '请输入文章标题': 'Please enter the title of the article',
    '文章内容（中文）': 'Article Content (Chinese)',
    '文章内容（俄文）': 'Article content (Russian)',
    '文章内容（英文）': 'Article Content (English)',
    '文章管理': 'Article management',
    '返回': 'Return',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
}