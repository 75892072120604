<template>
    <div class="_root footer-bg _pc-h-25 _pc-flex-row-center-mid">
        <div class="_w-max _flex-row-center-mid  _white  _pc-font-12" style="left:0;">
            <div class=" _pc-mar-right-20" style="opacity:0.8;">{{ 'Copyright © 2020 WWW.UXPHP.COM.All Rights Reserved 深圳市有小象科技有限公司版权所有' }}</div>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
                <div class="_flex-row-mid" style="opacity:0.8;">
                    <img class="_pc-w-10 _pc-mar-right-5" src="@/assets/images/footer-ico.png" alt="">{{ '粤ICP备17125031号' }}
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterBar',

    data() {
        return {
          
        };
    },

    mounted() {
      
    },

    methods: {
      
    },
};
var _hmt = _hmt || [];
(function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?03035463b0d091075c17b2bdb7eb4acc";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
})();
</script>

<style  scoped>
._root{
    background-color: #fff;
    text-align: center;
}

.footer-bg {
    background-color: rgb(37, 43, 59);
}

._w-max{
    width: 100%;
}
._flex-row-center-mid{
    display: flex;
    justify-content: center;
    align-items: center;
}
._white{
    color: #fff;
}
._pc-pad-y-10{
    padding-top: 10rem;
    padding-bottom: 10rem;;
}
._pc-font-12{
    font-size: 12rem;
}
._pos-abs-bottom{
    position: absolute;
    bottom: 0;
}
._pc-mar-right-20{
    margin-right: 20rem;
}
._flex-row-mid{
    display: flex;
    align-items: center;
}
a{
    color: #fff;
    text-decoration:none;
}
._pc-w-10{
    width: 10rem;
}
._pc-mar-right-5{
    margin-right: 5rem;
}
</style>