import Vue from 'vue';
import Vuex from 'vuex';


import siteInfo from '@/store/module/siteInfo';
import userInfo from '@/store/module/userInfo';
Vue.use(Vuex)

const store = new Vuex.Store({
    // 初始化数据
    state:{

    },

    //更新数据
    mutations:{

    },

    //更新数据方法（异步）
    actions:{

    },

    modules:[
        siteInfo,
        userInfo,
    ],

})

export default store;
