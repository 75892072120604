<template>
    <div class="_root head_bar _pc-pad-x-30">
        <div class="_pc-flex-row-mid  _pc-font-22 _b" style="display:flex;align-items:center;" >
            <div><img class="_pc-w-40 _pc-h-40 _pc-mar-right-5" :src="agentInfo.logo" alt=""></div>
            <div @click="onChange" style="cursor:pointer;" class="iconfont _pc-mar-x-10 _pc-font-22" :class="showNav?'icon-zhankaicaidan':'icon-shouqicaidan'"></div>
            <el-dropdown @command="changeIl18n" style="cursor: pointer;">
                <div>{{$t('think_lang')}}<i class="el-icon-arrow-down el-icon--right"></i></div>
                <el-dropdown-menu  slot="dropdown">
                    <el-dropdown-item command='zh_cn'>{{ $t('chinese') }}</el-dropdown-item>
                    <el-dropdown-item command='ru'>{{ $t('russian') }}</el-dropdown-item>
                    <el-dropdown-item command='en_us'>{{ $t('english') }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div style="display:flex;align-items:center;">
            <div class="_pc-font-14 _pc-flex-row-mid _pc-mar-right-50" style="cursor:pointer;">
                <img class="_pc-w-20 _pc-h-20 _pc-mar-right-5 _border-circle" src="@/assets/images/account-icon.png" alt="">
                <div class="_black">{{agentInfo.name}}</div>
            </div>
            <div class="_pc-font-14 _pc-flex-row-mid _pc-mar-right-50" style="cursor:pointer;" @click="toHomeIndex">
                <img class="_pc-w-10 _pc-h-10 _pc-mar-right-5" src="@/assets/images/home-icon.png" alt="">
                <div class="_black">{{ $t('staging') }}</div>
            </div>
            <div @click="logout" class="_pc-font-14 _black" style="cursor:pointer;">{{ $t('logout') }}</div>
        </div>
    </div>
</template>

<script>
import { logout, get_user_data } from '@/api/index';
import utils from '@/utils/utils';
export default {
    name: 'HeadBar',
    props:{
        showNav:Boolean,
    },
    data() {
        return {
            title:'',
            agentInfo:{},
        };
    },
    mounted(){
        this.get_user_data();
    },
    methods: {
        // 用户信息
        get_user_data(){
            get_user_data().then((res)=>{
                this.agentInfo = res.data;
            }).catch((err)=>{
                this.$message.error(err.msg)
            })
        },
        onChange(){
            this.$emit("onChange", !this.showNav);
        },
        // 返回首页
        toHomeIndex(){
            if(this.$route.fullPath === '/'){return}
            this.$router.push({path:'/'});
        },
        // 退出登录
        logout(){
            this.$MessageBox.confirm(this.$t('您确定退出当前账户吗？'), this.$t('退出登录确认'), {
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancellation'),
                type: 'warning'
            }).then(() => {
                this.$message.success(this.$t('logout_success'));
                this.$router.replace('/login');
                // localStorage.clear();
                utils.removeCookie('token');
                utils.removeCookie('agentId');
                utils.removeCookie('expires_time');
            });
        },
        //切换语言
        changeIl18n(type){
            if(type === this.$i18n.locale){return}
            this.$i18n.locale = type;
            window.localStorage.setItem('think-lang',type);
            window.location.reload()
        },
    },
};
</script>

<style scoped>
    ._root{
        height:55rem;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: #fff;
        justify-content: space-between;
    }
    .popver-list{
        max-height:250rem;
        border-radius:8rem;
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        overflow-y: auto;
        overflow-x:hidden ;
    }
    .popver-list::-webkit-scrollbar {
        display: none;
    }
    .popver-item{
        display:flex;
        font-size:14rem;
        padding-top:10rem;
        padding-bottom:10rem;
        border-bottom:1rem solid rgba(153, 153, 153, 0.226);
    }
    .popver-item:first-child{
        padding-top:0rem;
    }
    .popver-item:last-child{
        border-bottom:0;
    }
    ._flex-row-mid{
        display: flex;
        align-items: center;
    }
</style>