
export default{
  // 初始化数据
 state:{
    userInfo:{},
 },

 getters:{
    getUserInfo(state){
        if(Object.keys(state.userInfo).length == 0){
            state.userInfo = JSON.parse(window.localStorage.getItem('USER_INFO'));
        }
        return state.userInfo;
    },
 },

 //更新数据
 mutations:{
    //设置用户信息
    setUserInfo(state, site){
        state.userInfo = site;
        window.localStorage.setItem('USER_INFO',JSON.stringify(site));
    },

    //移除用户信息
    removeUserInfo(state){
        state.userInfo = {};
        window.localStorage.removeItem('USER_INFO');
    },
 },

 //更新数据方法（异步）
 actions:{
   
 },
}