<template>
    <div class="app" >
        <div class="_w-max _main-content">
            <HeadBar :showNav="showNav" :metaTitle="metaTitle" @onChange="onChange"></HeadBar>
            <div class="_content-head">
                <NavLeftBar :showNav="showNav" :navPath = "navPath"></NavLeftBar>
                <div class="_over-flow-y"><router-view/></div>
            </div>
        </div>
        <FooterBar></FooterBar>
    </div>
</template>

<script>
import HeadBar from '@/components/head-bar/head-bar.vue';
import NavLeftBar from '@/components/nav-left-bar/nav-left-bar.vue';
import FooterBar from '@/components/footer-bar/footer-bar.vue';
export default {
    name: 'MainComponents',
    components: {
      HeadBar,
      NavLeftBar,
      FooterBar
    },
    data(){
        return{
            showNav:false, //展开/收缩导航栏
            metaTitle:'',//栏目
            navPath:'/',//当前路径
        }
    },
    created(){
        let that = this;
        if(document.body.clientWidth <= 1024){
            that.showNav = true;
        }else{
            that.showNav = false
        }
    },
    watch:{
        '$router.app._route':{
            handler(newMetaTitle){
                this.metaTitle = newMetaTitle.matched;
                this.navPath = newMetaTitle.fullPath;
            },
        },
    },
    mounted(){
        let that = this;
        window.onresize = ()=>{
            if(document.body.clientWidth <= 1024){
                that.showNav = true;
            }else{
                that.showNav = false
            }
        }
    },
    methods:{
        onChange(data){
            this.showNav = data;
        },
    },
};
</script>

<style  scoped>
.app{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display:grid;
    grid-template-rows : 1fr auto;
}
._content-head{
    background-color: rgb(245,245,245);
    height: 100%;
    display:grid;
    grid-template-columns : auto 1fr;
    overflow:hidden;
}
._main-content{
    display: grid;
    grid-template-rows: auto 1fr;
    overflow:hidden;
}
._over-flow-y{
    overflow-y: scroll;
}
</style>