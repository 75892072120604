export default{
    think_lang:'Русский язык',
    home_index:'Домашняя страница',
    '用户列表':'Список пользователей',
    '系统设置':'Настройки системы',
    '登录':'Регистрация',
    error_404:'Текущая страница не существует',
    chinese: '简体中文',
    russian: 'Русский язык',
    english: 'English',
    staging: 'Рабочий стол',
    logout: 'Выход из системы',
    '代理仓每日出入库数据': 'Ежедневный вывоз данных из хранилища',
    '每日订单趋势': 'Тенденции ежедневных заказов',
    order_data: 'Данные заказа',
    see_more: 'Узнать больше',
    received: 'На складе',
    packaging: 'В упаковке',
    Packaged: 'Упакованы',
    '欢迎登录': 'Добро пожаловать в систему',
    '代理账号': 'Агентский счет',
    '请输入代理账号': 'Введите номер счета агента',
    '请输入登录密码': 'Введите пароль входа',
    '记住密码': 'Запомнить пароль',
    '登录密码': 'Пароль входа',
    order_info: 'Информация о заказе',
    order_no: 'Номер заказа',
    order_status: 'Статус заказа',
    '创建时间': 'Время создания',
    user_id: 'Пользователи ID',
    '用户名称': 'Имя пользователя',
    delivery_time: 'Время отгрузки',
    user_info: 'Информация о получателе',
    '姓名': 'Имя',
    '城市': 'Области',
    mobile: 'Телефон',
    '详细地址': 'Подробный адрес',
    '国家': 'Страна',
    area: 'Регион',
    zip_code: 'Почтовый индекс',
    wu: 'Нет',
    '包裹信息': 'Посылка информации',
    '快递单号': 'Экспедиционный номер',
    '物品名': 'Имя предмета',
    '计费重量': 'Стоимость',
    shelves_num: 'Товарное место',
    in_stock_img: 'Складские фотографии',
    '状态': 'Статус',
    report_time: 'Время подготовки',
    zhang_wu: 'Пока нет',
    enter_time: 'Время хранения',
    up_time: 'Время посадки',
    '打包信息': 'Упаковка информации',
    '仓库名称': 'Название склада',
    '路线名称': 'Название линии',
    '用户备注': 'Примечания пользователей',
    '平台备注': 'Замечания платформы',
    '打包序号': 'Серийный номер',
    '运单号': 'Номер накладной',
    '原始重量': 'Первоначальный вес',
    '实重': 'Реальный вес',
    '体积重': 'Объем',
    '长宽高': 'Длина, ширина, высота',
    '打包图片': 'Упаковка фотографий',
    '打包备注': 'Упаковка заметок',
    '计费信息': 'Расчетная информация',
    '实际运费': 'Фактический фрахт',
    '报价备注': 'Комментарии к предложениям',
    '实付金额': 'Выплаченные суммы',
    'RUB': 'RUB',
    '数据统计': 'Статистика данных',
    search_order_keyword: 'Поиск по ключевым словам заказа...',
    '全部': 'Все',
    treat_picked: 'Ожидающие сортировки товары',
    treat_pack: 'Оставайтесь упакованными',
    treat_quotation: 'Предложение ожидается',
    treat_pay: 'Подлежит оплате',
    treat_confirm: 'Подлежит подтверждению',
    treat_delivery: 'Ожидание отгрузки',
    stop_delivery: 'Отправлено',
    stop_complete: 'Завершено',
    '用户信息': 'Информация о пользователе',
    take_info: 'Информация о получении',
    '操作': 'Операция',
    '详情': 'Подробности',
    treat_warehousing: 'На складе',
    off_shelf: 'Сбежал',
    '已签收': 'Подписано',
    Unnamed_package: 'Неизвестный пакет',
    '物品信息': 'Информация о предметах',
    '体积': 'Объем',
    warehousing_warehouse: 'Склад',
    '添加用户': 'Добавить пользователя',
    '邮箱': 'Почтовый ящик',
    '请输入邮箱': 'Введите почтовый ящик',
    '密码': 'Пароль',
    '请输入密码': 'Введите пароль',
    '收件人姓名': 'Имя получателя',
    '请输入收件人姓名': 'Введите имя получателя',
    '联系电话': 'Контактный телефон',
    '请输入联系电话': 'Пожалуйста, введите контактный телефон',
    '请输入国家': 'Введите страну',
    '请输入城市': 'Введите город',
    '请输入地址': 'Введите адрес',
    Enable: 'Включить',
    close: 'Закрыть',
    '取消': 'Отменить',
    '修改': 'Изменения',
    '添加': 'Добавить',
    '搜索用户名': 'Поиск имени пользователя / ID...',
    '注册时间': 'Регистрация',
    '编辑': 'Редактирование',
    '共': 'Всего',
    '条': 'Статья',
    order_management: 'Управление заказами',
    order_detail: 'Подробности заказа',
    pack_index: 'Список посылок',
    '退出登录确认': 'Подтверждение выхода из системы',
    '您确定退出当前账户吗？': 'Вы уверены, что вышли из текущего счета?',
    '确认': 'Подтверждение',
    logout_success: 'Вы успешно вышли',
    '添加banner图': 'Добавить диаграмму banner',
    '修改banner图': 'Изменить диаграмму banner',
    '图片名称': 'Название изображения',
    image_path: 'Путь к изображению',
    image_ch: 'Китайские фотографии',
    image_ru: 'Фото на русском языке',
    image_us: 'Фото на английском языке',
    '排序': 'Сортировка',
    '删除': 'Удалить',
    '请输入图片名称': 'Введите имя изображения',
    image_rn: 'Фото на русском языке',
    display_or_not: 'Показать',
    display: 'Показать',
    hide: 'Скрыть',
    remark: 'Примечания',
    input_remark: 'Пожалуйста, введите примечание',
    set_up: 'Настройка',
    signature: 'Bнутренний сбор',
    grounding: 'Внутренний шельф',
    '大件包列表': 'Больш сумк список',
    '待出仓': 'Выход',
    '已出仓': 'Он вышел',
    '大件包号': 'Номер пакета',
    '搜索件包关键词': 'Ключевые слова...',
    pack_detail: 'детали',
    '大件包信息': 'Больш сумк информац',
    '大件包状态': 'Большой пакет',
    '包裹数据': 'Данные по упаковке',
    '已入库包裹': 'Посылка в хранилище',
    '待入库包裹': 'Оставайтесь в хранилище',
    '已下架包裹': 'Посылка убрана с полки',
    '已打包包裹': 'Пакеты упакованы',
    '国外已签收': 'Внутри страны подписано',
    '本月已完成订单数': 'Заказ завершен в этом месяце',
    '本月订单总额': 'Общая сумма заказов за этот месяц',
    '用户数据': 'данные пользователя',
    '用户总数': 'Общее число пользователей',
    '本月新增用户': 'Новые пользователи в этом месяце',
    '代理仓数据': 'Данные агентского склада',
    '在库包裹': 'Посылка в хранилище',
    '今日入库': 'Сегодня склад',
    '今日打包': 'Упакуйте сегодня',
    '今日国外签收': 'Подпишите сегодня дома',
    order_price: 'Сумма заказа',
    order_sum: 'Количество заказов',
    be_put_in_storage: 'На складе',
    stock_removal: 'Выход из хранилища',
    '请输入名称': 'Введите имя',
    '联系地址': 'Контактный адрес',
    merged_pack: 'Объединены',
    '基础设置': 'Основные параметры',
    '页面设置': 'Настройка страницы',
    '公告设置': 'Настройка объявления',
    '添加公告': 'Добавить объявление',
    '公告标题': 'Заголовок сообщения',
    notice_content: 'Содержание объявления',
    '浏览量': 'Количество просмотров',
    '请输入公告标题': 'Введите заголовок объявления',
    '修改公告': 'Изменить объявление',
    '请输入公告内容': 'Пожалуйста, введите объявление',
    '提交': 'Представлено',
    image_type: 'Тип изображения',
    home_page: 'Главная страница',
    logistics_page: 'Страница поиска логистики',
    faq_page: 'Страница часто задаваемых вопросов',
    package_page: 'Страница прогноза посылок',
    my_page: 'О нашей странице',
    freight_page: 'Страница сметы расходов на перевозку',
    '导出': 'приведение',
    '用户标识': 'Идентификатор пользователя',
    '请输入用户标识': 'Введите идентификатор пользователя',
    '只能是1~6位的数字或字母': 'Это могут быть только цифры или буквы от 1 до 6 бит',
    '入库图片': 'Складские фотографии',
    leave_a_message: 'Сообщение',
    input_content: 'Введите содержимое',
    message_record: 'Запись сообщения',
    select_order: 'Пожалуйста, выберите заказ',
    '添加包裹': 'Добавить пакет',
    user_uid: 'Пользовательский UID',
    report_no: 'Номер посылки',
    item_name: 'Название предмета',
    caterty_type: 'Категория груза',
    '包裹备注': 'Примечания к пакету',
    '活跃情况': 'Активность',
    '请选择活跃时间': 'Выберите активное время.',
    '时间段未下单': 'Период без заказа',
    '时间段下单过': 'Переход через промежуток времени',
    '时间段未报备': 'Период не указан',
    '时间段报备过': 'Время готово',
    '时间段未访问': 'Время посещения не состоялось',
    '时间段访问过': 'Время посещения',
    '首次访问': 'Первый визит',
    '关键字搜索': 'Поиск по ключевым словам',
    '开始时间': 'Время начала',
    '结束时间': 'Время окончания',
    search_report_keyword: 'Ищи ключевое слово на упаковке...',
    '公告内容（中文）': 'Содержание объявления (по-китайски)',
    '公告内容（俄文）': 'Содержание объявления (на русском языке)',
    '公告内容（英文）': 'Содержание объявления (английский)',
    '公告标题（中文）': 'Название объявления (по-китайски)',
    '公告标题（俄文）': 'Название объявления (на русском языке)',
    '公告标题（英文）': 'Название объявления (английский)',
    '包裹类型': 'Тип упаковки',
    '别称': 'другое название',
    '州/城市': 'область/город',
    goods_money: 'Декларировать ценность',
    '是否需要木架': 'Нужна ли деревянная рама',
    yes: 'да',
    no: 'Нет',
    '费用信息': 'Информация о расходах',
    collect_payment: 'До суммы',
    build_wooden_frame: 'Деревянные',
    reinforce_free: 'Плата за деревянную полку',
    premium_fee: 'страхование',
    '请求失败': 'Запрос отклонен',
    '标签': 'Этикетка',
    '出库时间': 'Время исходящего',
    '签收时间': 'Время регистрации',
    '关键词': 'Ключевое слово',
    '清关时间': 'Время очистки',
    '包号日期': 'Дата набора',
    '请选择搜索时间': 'Пожалуйста, выберите время поиска',
    '搜索时间': 'время поиска',
    '批量导出包裹': 'Экспортируйте пакет оптом',
    '编辑包裹': 'Редактировать пакет',
    '重量': 'выгруженный',
    '长': 'Степень',
    '宽': 'Широкий',
    '高': 'Высокий',
    '重量': '重量',
    '请填写ID号段为900001-999999范围内的数字': 'Пожалуйста, заполните номер идентификатора в диапазоне 900001 - 99999',
    '文章管理': 'Управление статьями',
    '添加文章': 'Добавить статью',
    '文章图片': 'Фотография статьи',
    '文章名称': 'Название статьи',
    '时间': 'время',
    '修改文章': 'Изменить статью',
    '文章标题（中文）': 'Заголовок статьи (по-китайски)',
    '文章标题（俄文）': 'Заголовок статьи (на русском языке)',
    '文章标题（英文）': 'Заголовок статьи (на английском)',
    '请输入文章标题': 'Пожалуйста, введите заголовок статьи',
    '文章内容（中文）': 'Содержание статьи (по-китайски)',
    '文章内容（俄文）': 'Содержание статьи (на русском языке)',
    '文章内容（英文）': 'Содержание статьи (на английском)',
    '文章管理': 'Управление статьями',
    '返回': 'вернут',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
    '重量': '重量',
}