<template>
    <div id="app">
        <router-view />
    </div>
</template>


<script>

export default {
    name: 'App',

    created(){
        this.$store.dispatch('getSiteName');
    },

    methods:{

    },
}
</script>

<style>
body{
    margin:0;
}
input{border:unset;outline-style: none;}
textarea{border:unset;outline-style: none;}
select{outline-style: none;background-color: unset;}

a{text-decoration:unset;}
a{color: unset;}
a:hover{color: unset;}

:root{
    --theme-color:#4284DC;
    --theme-color-bg:#4284DC;
}


.theme-color{color:var(--theme-color);}
.theme-color-bg{background-color:var(--theme-color-bg);}
</style>
